import React, { useEffect, memo, useState } from "react";
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Card from '../../../components/Card';
import DeleteConfirmation from "./DeleteConfirmation";
import FullSiteTrainingConfirmation from "./FullSiteTrainingConfirmation";
import axios from "axios";
import { info, error, success } from "../../../notify";

const Site = memo((props) => {
  let history = useNavigate();
  let location = useLocation();
  const userRole = sessionStorage.getItem("userRole");

  let siteLabel = "site"
  let siteLabelC = "Site"
  if (userRole === "ADMIN") {
    siteLabel = "client"
    siteLabelC = "Client"
  }

  const ERR_NETWORK_MESSAGE = 'Server is not available.'
  const token = sessionStorage.getItem("revizeTrainingToken");
  const [userSiteData, setUserSiteData] = useState([])
  const userSiteApi = `${process.env.REACT_APP_SERVER_URL}/api/website/`;
  const addSiteApi = `${process.env.REACT_APP_SERVER_URL}/api/website`;
  const editSiteApi = `${process.env.REACT_APP_SERVER_URL}/api/website/`;
  const deleteSiteApi = `${process.env.REACT_APP_SERVER_URL}/api/website/`;
  const siteCountApi = `${process.env.REACT_APP_SERVER_URL}/api/website/count`;
  const fullSiteTrainingApi = `${process.env.REACT_APP_SERVER_URL}/api/full_site_training/`;
  const getPageLimitApi = `${process.env.REACT_APP_SERVER_URL}/api/get_page_limit/`;
  const setPageLimitURL = `${process.env.REACT_APP_SERVER_URL}/api/set-page-limit/`;

  const defaultBanenrURL = "https://images.unsplash.com/photo-1557683311-eac922347aa1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=829&q=80";

  const [showAddSite, setShowAddSite] = useState(false);
  const handleCloseAddSite = () => {
    setEnableFullSiteTraining(false);
    setEnableFileTrain(false);
    setBannerUrlError("");
    setRateLimitError("");
    setBotNameError("");
    setSiteNameError("");
    setDomainUrlError("");
    setAutoTrainingIntervalError("");
    setEnableFullSiteTrainingError("");
    setShowAddSite(false);
  }
  const handleShowAddSite = () => setShowAddSite(true);

  const [showEditSite, setShowEditSite] = useState(false);
  const handleCloseEditSite = () => {
    setBannerUrlError("");
    setRateLimitError("");
    setBotNameError("");
    setSiteNameError("");
    setDomainUrlError("");
    setAutoTrainingIntervalError("");
    setEnableFileTrain(false);
    setEnableFullSiteTraining(false);
    setAutoTrainingInterval("");
    setQdrantDbUrl("");
    setEnableDelete(true);
    setShowEditSite(false);
  }

  const handleShowEditSite = (
    siteId, siteName, key, botName, bannerUrl, rateLimit,
    enableFileTrain, domainUrl, qdrant_db_url, enableFullSiteTraining,
    autoTrainingInterval, enableDelete
  ) => {
    setSiteId(siteId);
    setSiteName(siteName);
    setKey(key);
    setBotName(botName);
    setBannerUrl(bannerUrl);
    setRateLimit(rateLimit);
    setEnableFileTrain(enableFileTrain);
    setDomainUrl(domainUrl);
    setQdrantDbUrl(qdrant_db_url)
    setEnableFullSiteTraining(enableFullSiteTraining);
    setAutoTrainingInterval(autoTrainingInterval);
    setEnableDelete(enableDelete);
    setShowEditSite(true);
  };



  const [showSiteScript, setShowSiteScript] = useState(false);
  const handleCloseSiteScript = () => setShowSiteScript(false);
  const handleShowSiteScript = (botName, key) => {
    let htmlStr =
      `<iframe id="botIframe" align="right" scrolling="no" hidden="hidden" 
          src="${process.env.REACT_APP_CHATBOT_URL}/?websiteRef=${key}" style="position:fixed; right:10px; bottom:45px; width:400px; height:620px; 
            box-shadow:0 20px 80px rgba(0,0,0,.6); border-radius:5px; overflow:hidden; border:none;">
      </iframe> 
      <button id="button1" onclick="hideToggle();" 
          style="position:fixed; right:10px; bottom:5px; width:130px; padding:10px; 
          background-color:rgb(71, 85, 105); color:white; font-weight:600; 
          border-radius:10px; overflow: hidden; text-overflow: ellipsis;">Show ${botName}
      </button>`
    let jsStr =
      `<script>
        function hideToggle() {
          let iframe = document.getElementById("botIframe");
          let hidden = iframe.getAttribute("hidden");
          const show_text = "Show ${botName}";
          const hide_text = "Hide ${botName}";

          if (hidden) {
            iframe.removeAttribute("hidden");
            document.getElementById("button1").innerText = hide_text.length > 25 ? hide_text.substring(0, 25) + "..." : hide_text;
          } else {
            iframe.setAttribute("hidden", "hidden");
            document.getElementById("button1").innerText = show_text.length > 25 ? show_text.substring(0, 25) + "..." : show_text;
          }
        }
      </script>`

    setHtmlStr(htmlStr);
    setJavaScriptStr(jsStr)
    setShowSiteScript(true);
  };

  const [showDeleteSite, setShowDeleteSite] = useState(false);
  const handleCloseDeleteSite = () => setShowDeleteSite(false);
  const handleShowDeleteSite = (siteId, siteName, key) => {
    setSiteId(siteId);
    setSiteName(siteName);
    setKey(key);
    setDeleteMessage("Are you sure you want to delete the " + siteLabel + " " + siteName + "? Once " + siteLabel +
      " is deleted, it will also delete all trained " + siteLabel + " data.");
    setShowDeleteSite(true);
    setSiteName("")
  };
  const [deleteMessage, setDeleteMessage] = useState(null);

  const [showFSTConfirm, setShowFSTConfirm] = useState(false);
  const [proceedFST, setProceedFST] = useState(false);
  const handleCloseFSTConfirm = () => setShowFSTConfirm(false);
  const handleShowFSTConfirm = (siteId, siteName, key, enable_full_site_training) => {
    const boolValue = enable_full_site_training === true;
    console.log('boolValue', boolValue)
    if (boolValue) {
      setConfirmFTSMessage("Do you want to continue with Full Site Training for  " + siteLabel + " " + siteName + "?");
    } else {
      setConfirmFTSMessage("Full Site Training is not enabled for " + siteLabel + " " + siteName + ". Please Enable Full Site Training and try again.");
    }
    setSiteId(siteId);
    setSiteName(siteName);
    setKey(key);
    setProceedFST(boolValue);
    setShowFSTConfirm(true);
  };
  const [confirmFTSMessage, setConfirmFTSMessage] = useState(null);

  const [siteId, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");
  const [key, setKey] = useState("");
  const [enableFileTrain, setEnableFileTrain] = useState(false);
  const [enableFullSiteTraining, setEnableFullSiteTraining] = useState(false);
  const [rateLimit, setRateLimit] = useState(0);
  const [autoTrainingInterval, setAutoTrainingInterval] = useState(0);
  const [botName, setBotName] = useState("");
  const [bannerUrl, setBannerUrl] = useState(defaultBanenrURL);
  const [domainUrl, setDomainUrl] = useState("");
  const [siteCount, setSiteCount] = useState(0);
  const [currentSiteInfo, setCurrentSiteInfo] = useState({
    siteName: '',
    botName: '',
    domainUrl: '',
    qdrantDbUrl: '',
    bannerUrl: '',
    enableFullSiteTraining: false,
    enableFileTrain: false,
    autoTrainingInterval: 0,
    rateLimit: 0,
    enableDelete: true
  });


  const [htmlStr, setHtmlStr] = useState("");
  const [javaScriptStr, setJavaScriptStr] = useState("");

  const [enableDelete, setEnableDelete] = useState(true);

  const handleSite = (event) => {
    setSiteName(event.trim());
  };

  const handleRateLimit = (event) => {
    if (event === "") {
      setRateLimit(0);
    } else {
      setRateLimit(event);
    }
  };

  const handleAutoTrainingInterval = (event) => {
    if (event === "") {
      setAutoTrainingInterval(0);
    } else {
      setAutoTrainingInterval(event);
    }
  };

  const handleBotName = (event) => {
    setBotName(event.trim());
  };

  const handleBannerUrl = (event) => {
    setBannerUrl(event.trim());
  };

  const handleEnableFileTrain = (event) => {
    setEnableFileTrain(event);
  };

  const handleEnableFullSiteTraining = (event) => {
    const boolValue = event === 'true';
    setEnableFullSiteTraining(boolValue);
  };

  const handleDomainUrl = (event) => {
    setDomainUrl(event.trim());
  };

  const [siteNameError, setSiteNameError] = useState('')
  const [rateLimitError, setRateLimitError] = useState('')
  const [autoTrainingIntervalError, setAutoTrainingIntervalError] = useState('')
  const [botNameError, setBotNameError] = useState('')
  const [bannerUrlError, setBannerUrlError] = useState('')
  const [domainUrlError, setDomainUrlError] = useState('')
  const [enableFullSiteTrainingError, setEnableFullSiteTrainingError] = useState(false)

  const validateSiteName = (event) => {
    event.preventDefault();
    const siteNamePattern = /^[ a-zA-Z0-9,-|()&]+$/;

    // Regular expression to match any single special character
    const specialCharPattern = /[^\w\s,-|()&]/g;

    // Check for invalid characters
    const specialCharMatches = siteName.match(specialCharPattern);

    if (!siteNamePattern.test(siteName) || (specialCharMatches && specialCharMatches.length === 1)) {
      setSiteNameError(siteLabelC + " Name is invalid.");
    } else {
      setSiteNameError("");
    }
  }

  const validateRateLimit = (event) => {
    event.preventDefault();
    if (rateLimit < 0) {
      setRateLimitError("Rate Limit should not be less than 0.");
    } else {
      setRateLimitError("");
    }
  }

  const validateAutoTrainingInterval = (event) => {
    event.preventDefault();
    if (autoTrainingInterval < 0) {
      setAutoTrainingIntervalError("Auto Training Interval should not be less than 0.");
    } else if (autoTrainingInterval > 365) {
      setAutoTrainingIntervalError("Auto Training Interval should not be greater than 365 (1 year).");
    } else {
      setAutoTrainingIntervalError("");
    }
  }

  const validateBotName = (event) => {
    event.preventDefault();
    // URL validation using regular expressions
    // const botNamePattern = /[a-zA-Z]/;
    const botNamePattern = /^[ a-zA-Z0-9,-|()&]+$/;

    if (!botNamePattern.test(botName)) {
      setBotNameError("Bot Name is invalid");
    } else if (botName.length < 3) {
      setBotNameError("Bot Name should have atleast 3 Character");
    } else if (botName.length > 50) {
      setBotNameError("Bot Name should not exceed 50 Characters");
    } else {
      setBotNameError("");
    }
  }

  const validateBannerUrl = (event) => {
    event.preventDefault();
    // URL validation using regular expressions
    const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
    console.log('urlPattern in validateBannerUrl');

    if (bannerUrl === "") {

    }

    if (!urlPattern.test(bannerUrl)) {
      setBannerUrlError("Banner URL is invalid");
    } else {
      setBannerUrlError("");
    }
  }

  const validateDomainUrl = (event) => {
    event.preventDefault();
    // URL validation using regular expressions
    const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
    console.log('urlPattern in validateDomainUrl');

    if (domainUrl === "") {
      setDomainUrlError("");
    } else if (!urlPattern.test(domainUrl)) {
      setDomainUrlError("Domain URL is invalid");
    } else {
      setDomainUrlError("");
    }
  }

  let loaded = false;

  useEffect(() => {
    if (!token) {
      history('/auth/sign-in')
    }
    if (!loaded && token) {
      // eslint-disable-next-line
      loaded = true;
      fetchData();
      fetchSiteCount()
    }

  }, [userSiteApi]);

  async function fetchSiteCount() {
    const headers = { "accept": "application/json", "Authorization": "Bearer " + token };

    axios.get(siteCountApi, { headers: headers })
      .then((response) => {
        const data = response.data;
        setSiteCount(data)
      }).catch((err) => {
        if (err === 'ERR_NETWORK') {
          error(ERR_NETWORK_MESSAGE, true)
        } else {
          error(err.response.data.detail, true);
          if (err.response.data.detail == "invalid email or password") {
            sessionStorage.removeItem("revizeTrainingToken");
            sessionStorage.removeItem("loggedInUserId");
            sessionStorage.removeItem("userRole");
            sessionStorage.removeItem("firstName");
            window.location.href = '/auth/sign-in';
          }
        }
        history('/dashboard');
      });
  }

  async function fetchData(offset) {
    const headers = {
      "accept": "application/json",
      "Authorization": "Bearer " + token
    };

    let limit = itemsPerPage; // Default value for limit, in case fetching page limit fails

    const loggedInUserId = sessionStorage.getItem("loggedInUserId");

    try {
      // Fetch the page limit first, before calling userSiteApi
      if (loggedInUserId) {
        try {
          const pageLimitResponse = await axios.get(getPageLimitApi, {
            headers: headers,
            params: { user_id: loggedInUserId }
          });

          const pageLimitData = pageLimitResponse.data;
          setItemsPerPage(pageLimitData.page_limit);
          limit = pageLimitData.page_limit;  // Set the limit from the page limit response

        } catch (pageLimitErr) {
          // Handle errors from /api/get_page_limit/ call
          if (pageLimitErr.response) {
            error(pageLimitErr.response.data.detail, true);
          } else {
            console.error("Error fetching page limit:", pageLimitErr);
          }
          // Continue with the default `itemsPerPage` if fetching page limit fails
        }
      }

      // Now, call userSiteApi using the fetched or default limit
      const skip = offset;  // Calculate the skip based on offset
      const response = await axios.get(userSiteApi, {
        headers: headers,
        params: { skip, limit }
      });

      const data = response.data;
      const userSites = data.map(element => element);
      setUserSiteData(userSites);

    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          sessionStorage.removeItem("revizeTrainingToken");
          sessionStorage.removeItem("loggedInUserId");
          sessionStorage.removeItem("userRole");
          sessionStorage.removeItem("firstName");
          window.location.href = '/auth/sign-in';
        } else {
          error(err.response.data.detail, true);
        }
      } else if (err.message === 'ERR_NETWORK') {
        error(ERR_NETWORK_MESSAGE, true);
      } else {
        console.error(err);
      }

      history('/dashboard');
    }
  }


  const addSite = () => {
    if (token) {
      const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
      var data = JSON.stringify({
        "name": siteName,
        "rate_limit": rateLimit,
        "bot_name": botName,
        "banner_url": bannerUrl.length > 0 ? bannerUrl : defaultBanenrURL,
        "domain_url": domainUrl.length > 0 ? domainUrl : '',
        "qdrant_db_url": qdrantDbUrl,
        "auto_training_interval": autoTrainingInterval,
        "enable_full_site_training": enableFullSiteTraining,
        "enable_delete": enableDelete
      });

      axios.post(addSiteApi, data, { headers: headers })
        .then((response) => {
          const data = response.data;
          setUserSiteData(oldData => [...oldData, data]);
          success(siteLabelC + " added successfully.", true);
        })
        .catch((err) => {
          if (err === 'ERR_NETWORK') {
            error(ERR_NETWORK_MESSAGE, true);
          } else {
            error(err.response.data.detail, true);
          }
        });

      setSiteName("");
      setRateLimit(0);
      setBotName("");
      setAutoTrainingInterval(0);
      setEnableFullSiteTraining(false);
      setEnableDelete(true);
    }
  };


  const editSite = () => {
    if (token) {
      const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };

      const hasChanged = siteName !== currentSiteInfo.siteName ||
        rateLimit !== currentSiteInfo.rateLimit ||
        botName !== currentSiteInfo.botName ||
        bannerUrl !== currentSiteInfo.bannerUrl ||
        enableFileTrain !== currentSiteInfo.enableFileTrain ||
        domainUrl !== currentSiteInfo.domainUrl ||
        qdrantDbUrl !== currentSiteInfo.qdrantDbUrl ||
        enableFullSiteTraining !== currentSiteInfo.enableFullSiteTraining ||
        autoTrainingInterval !== currentSiteInfo.autoTrainingInterval ||
        enableDelete !== currentSiteInfo.enableDelete;

      if (hasChanged) {
        var data = JSON.stringify({
          "website_request": {
            "name": siteName,
            "rate_limit": rateLimit,
            "bot_name": botName,
            "banner_url": bannerUrl,
            "enable_file_train": enableFileTrain,
            "domain_url": domainUrl,
            "qdrant_db_url": qdrantDbUrl,
            "enable_full_site_training": enableFullSiteTraining,
            "auto_training_interval": autoTrainingInterval,
            "enable_delete": enableDelete
          }
        });

        axios.put(editSiteApi + siteId, data, { headers: headers })
          .then((response) => {
            setCurrentSiteInfo({
              siteName: siteName,
              botName: botName,
              domainUrl: domainUrl,
              qdrantDbUrl: qdrantDbUrl,
              bannerUrl: bannerUrl,
              enableFullSiteTraining: enableFullSiteTraining,
              enableFileTrain: enableFileTrain,
              autoTrainingInterval: autoTrainingInterval,
              rateLimit: rateLimit,
              enableDelete: enableDelete
            });


            setUserSiteData(
              userSiteData.filter((val) => {
                return val.id !== siteId;
              })
            );
            updateUserSiteData(siteId);
            success(siteLabelC + " updated successfully.", true);
            handleCloseEditSite();
          })
          .catch((err) => {
            if (err === 'ERR_NETWORK') {
              error(ERR_NETWORK_MESSAGE, true);
            } else {
              error(err.response.data.detail, true);
            }
          });
      }
    }
  };




  const trainFullSite = () => {

    const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };

    axios.post(fullSiteTrainingApi + siteId, { headers: headers })
      .then((response) => {
        info("Full Site Training request received for " + siteLabelC + ". Configured Admin and Site Admin will be notified through email once the process is completed.", true);
      })
      .catch((err) => {
        if (err === 'ERR_NETWORK') {
          error(ERR_NETWORK_MESSAGE, true)
        } else {
          error(err.response.data.detail, false)
        }
      });

    setShowFSTConfirm(false);

  };

  const deleteSite = () => {
    const headers = { "accept": "application/json", "Authorization": "Bearer " + token };

    axios.delete(deleteSiteApi + siteId, { data: { "websiteRef": key } }, { headers: headers })
      .then((response) => {
        const data = response.data;
        success(data, true);
        setUserSiteData(
          userSiteData.filter((val) => {
            return val.id !== siteId;
          })
        );
      })
      .catch((err) => {
        if (err === 'ERR_NETWORK') {
          error(ERR_NETWORK_MESSAGE, true)
        } else {
          error(err.response.data.detail, true)
        }
      });

    setShowDeleteSite(false);

  };

  const updateUserSiteData = (id) => {
    const currentSiteIndex = userSiteData.findIndex((site) => site.id === id);
    const updatedSite = Object.assign({}, userSiteData[currentSiteIndex]);
    updatedSite.name = siteName;
    updatedSite.rate_limit = rateLimit;
    updatedSite.bot_name = botName;
    updatedSite.banner_url = bannerUrl;
    updatedSite.enable_file_train = enableFileTrain;
    updatedSite.domain_url = domainUrl;
    updatedSite.enable_full_site_training = enableFullSiteTraining;
    updatedSite.auto_training_interval = autoTrainingInterval;
    updatedSite.enable_delete = enableDelete;
    const newUserSiteData = userSiteData.slice();
    newUserSiteData[currentSiteIndex] = updatedSite;
    setUserSiteData(newUserSiteData);
  };

  const HtmlContentDisplay = ({ htmlContent }) => {
    return <pre>{htmlContent}</pre>;
  };

  const areAllFieldsFilled = siteName !== "" && botName !== "" && siteNameError === "" && botNameError === "" && bannerUrlError === "" && autoTrainingIntervalError === "" && domainUrlError === "" && rateLimitError === "";

  useEffect(() => {
    if (showAddSite) {
      setSiteName("")
      setBotName("")
      setSiteNameError("")
      setBotNameError("")
      setBannerUrlError("")
    }
  }, [showAddSite]);


  const [itemsPerPage, setItemsPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRangeStart, setCurrentRangeStart] = useState(1);
  const pagesToShow = 10;


  const totalPages = Math.ceil(siteCount / itemsPerPage);


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    fetchData(offset);
  }, [currentPage]);


  const handlePrevRange = () => {
    setCurrentRangeStart(prev => Math.max(prev - pagesToShow, 1));
  };

  // Handle next range button
  const handleNextRange = () => {
    setCurrentRangeStart(prev => Math.min(prev + pagesToShow, totalPages));
  };

  // Calculate visible page numbers
  const visiblePageNumbers = [];
  for (let i = currentRangeStart; i < Math.min(currentRangeStart + pagesToShow, totalPages + 1); i++) {
    visiblePageNumbers.push(i);
  }


  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    const setPageLimit = async () => {
      const headers = {
        "accept": "application/json",
        "Authorization": "Bearer " + token
      };
      const loggedInUserId = sessionStorage.getItem("loggedInUserId");

      if (itemsPerPage != null) {
        try {
          await axios.post(setPageLimitURL,
            {
              page_limit: itemsPerPage
            },
            {
              headers: headers,
              params: { user_id: loggedInUserId }
            }
          );

          fetchData();

        } catch (setLimitErr) {
          console.error('Error setting page limit:', setLimitErr);
          error('Error setting page limit', true);
        }
      }
    };

    // Call the setPageLimit function
    setPageLimit();

  }, [itemsPerPage]);


  const [qdrantDbUrl, setQdrantDbUrl] = useState('');
  const [qdrantDbUrlError, setQdrantDbUrlError] = useState('');

  const validateQdrantDbUrl = (event) => {
    event.preventDefault();
    const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;

    if (!urlPattern.test(qdrantDbUrl)) {
      setQdrantDbUrlError("Qdrant DB URL is invalid");
    } else {
      setQdrantDbUrlError("");
    }
  };


  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between flex-wrap">
            <div className="header-title">
              {userRole === "ADMIN" ?
                <h4 className="card-title mb-0">Clients</h4>
                :
                <h4 className="card-title mb-0">Sites</h4>
              }
            </div>
            {userRole === "ADMIN" &&
              <div>
                <Button className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShowAddSite}>
                  <i className="btn-inner">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                  </i>
                  <span>Add Client</span>
                </Button>
                <Modal
                  size="xl"
                  style={{ maxWidth: '1280px', width: '100%', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                  show={showAddSite} onHide={handleCloseAddSite}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Client</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col span={12}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            onChange={e => handleSite(e.target.value)}
                            maxLength={50}
                            onBlur={validateSiteName} />
                          <span style={{ color: "red" }}>
                            {siteNameError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Bot Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Bot Name"
                            onChange={e => handleBotName(e.target.value)}
                            maxLength={50}
                            onBlur={validateBotName} />
                          <span style={{ color: "red" }}>
                            {botNameError}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Domain URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Domain URL"
                        defaultValue=''
                        onChange={e => handleDomainUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateDomainUrl} />
                      <span style={{ color: "red" }}>
                        {domainUrlError}
                      </span>
                    </Form.Group>

                    {/* <Form.Group className="mb-3" controlId="formBasicQdrantDbUrl">
                      <Form.Label>Qdrant DB URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Qdrant DB URL"
                        value={qdrantDbUrl}
                        onChange={e => setQdrantDbUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateQdrantDbUrl}
                      />
                      <span style={{ color: "red" }}>
                        {qdrantDbUrlError}
                      </span>
                    </Form.Group> */}


                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Banner URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Banner URL"
                        defaultValue={defaultBanenrURL}
                        onChange={e => handleBannerUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateBannerUrl} />
                      <span style={{ color: "red" }}>
                        {bannerUrlError}
                      </span>
                    </Form.Group>

                    <Row>
                      <Col span={12}>
                        {userRole === "ADMIN" &&
                          <div>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Enable Full Site Training</Form.Label>
                              <Form.Select
                                className=" form-select-sm mb-3"
                                aria-label=".form-select-sm example"
                                defaultValue={enableFullSiteTraining}
                                onChange={e => handleEnableFullSiteTraining(e.target.value)}>
                                <option value="false">Disable</option>
                                <option value="true">Enable</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        }
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Enable File Training</Form.Label>
                          <Form.Select
                            className=" form-select-sm mb-3"
                            aria-label=".form-select-sm example"
                            defaultValue={enableFileTrain}
                            onChange={e => handleEnableFileTrain(e.target.value)}>
                            <option value="false">Disable</option>
                            <option value="true">Enable</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col span={12}>
                        {userRole === "ADMIN" &&
                          <div>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Auto Training Interval (In Days)</Form.Label>
                              <Form.Control
                                disabled={!enableFullSiteTraining}
                                type="number"
                                placeholder="Auto Training Interval (In Days)"
                                defaultValue="0"
                                min="0"
                                onChange={e => handleAutoTrainingInterval(e.target.value)}
                                onBlur={validateAutoTrainingInterval} />
                              <span style={{ color: "red" }}>
                                {autoTrainingIntervalError}
                              </span>
                            </Form.Group>
                          </div>
                        }
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Rate Limit</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Rate Limit"
                            defaultValue="0"
                            onChange={e => handleRateLimit(e.target.value)}
                            onBlur={validateRateLimit}
                            min="0" />
                          <span style={{ color: "red" }}>
                            {rateLimitError}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                      <Form.Group controlId="formEnableDelete" style={{ margin: 0 }}>
                        <Form.Check
                          type="checkbox"
                          label="Delete protection"
                          checked={enableDelete}
                          onChange={(e) => setEnableDelete(e.target.checked)}
                        />
                      </Form.Group>
                      <div>
                        <Button variant="secondary" onClick={handleCloseAddSite}>
                          Cancel
                        </Button>{' '}
                        <Button
                          disabled={!areAllFieldsFilled}
                          variant="primary"
                          onClick={() => {
                            addSite();
                            handleCloseAddSite();
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </Modal.Footer>

                </Modal>
              </div>
            }
          </Card.Header>
          <Card.Body>
            {(userRole === "ADMIN" || userRole === "SITEADMIN") &&
              <div>
                <Modal
                  size="xl"
                  style={{
                    maxWidth: '1280px',
                    width: '100%',
                    margin: 'auto',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  show={showEditSite}
                  onHide={handleCloseEditSite}
                  onShow={() => {
                    setCurrentSiteInfo({
                      siteName: siteName,
                      botName: botName,
                      domainUrl: domainUrl,
                      qdrantDbUrl: qdrantDbUrl,
                      bannerUrl: bannerUrl,
                      enableFullSiteTraining: enableFullSiteTraining,
                      enableFileTrain: enableFileTrain,
                      autoTrainingInterval: autoTrainingInterval,
                      rateLimit: rateLimit,
                      enableDelete: enableDelete
                    });
                  }}
                >
                  <Modal.Header closeButton>
                    {userRole === "ADMIN" ?
                      <Modal.Title>Edit Client</Modal.Title>
                      :
                      <Modal.Title>Edit Site</Modal.Title>
                    }
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col span={12}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            defaultValue={siteName}
                            onChange={e => handleSite(e.target.value)}
                            maxLength={50}
                            onBlur={validateSiteName} />
                          <span style={{ color: "red" }}>
                            {siteNameError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Bot Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Bot Name"
                            defaultValue={botName}
                            onChange={e => handleBotName(e.target.value)}
                            maxLength={50}
                            onBlur={validateBotName} />
                          <span style={{ color: "red" }}>
                            {botNameError}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Domain URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Domain URL"
                        defaultValue={domainUrl}
                        onChange={e => handleDomainUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateDomainUrl} />
                      <span style={{ color: "red" }}>
                        {domainUrlError}
                      </span>
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicQdrantDbUrl">
                      <Form.Label>Qdrant DB URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Qdrant DB URL"
                        defaultValue={qdrantDbUrl}
                        onChange={e => setQdrantDbUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateQdrantDbUrl} />
                      <span style={{ color: "red" }}>
                        {qdrantDbUrlError}
                      </span>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Banner URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Banner URL"
                        defaultValue={bannerUrl}
                        onChange={e => handleBannerUrl(e.target.value)}
                        maxLength={500}
                        onBlur={validateBannerUrl} />
                      <span style={{ color: "red" }}>
                        {bannerUrlError}
                      </span>
                    </Form.Group>
                    <Row>
                      <Col span={12}>
                        {userRole === "ADMIN" &&
                          <div>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Enable Full Site Training</Form.Label>
                              <Form.Select
                                className=" form-select-sm mb-3"
                                aria-label=".form-select-sm example"
                                defaultValue={enableFullSiteTraining}
                                onChange={e => handleEnableFullSiteTraining(e.target.value)}>
                                <option value="false">Disable</option>
                                <option value="true">Enable</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        }
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Enable File Training</Form.Label>
                          <Form.Select
                            className=" form-select-sm mb-3"
                            aria-label=".form-select-sm example"
                            defaultValue={enableFileTrain}
                            onChange={e => handleEnableFileTrain(e.target.value)}>
                            <option value="false">Disable</option>
                            <option value="true">Enable</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col span={12}>
                        {userRole === "ADMIN" &&
                          <div>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Auto Training Interval (In Days)</Form.Label>
                              <Form.Control
                                disabled={!enableFullSiteTraining}
                                type="number"
                                placeholder="Auto Training Interval (In Days)"
                                defaultValue={autoTrainingInterval}
                                onChange={e => handleAutoTrainingInterval(e.target.value)}
                                onBlur={validateAutoTrainingInterval} />
                              <span style={{ color: "red" }}>
                                {autoTrainingIntervalError}
                              </span>
                            </Form.Group>
                          </div>
                        }
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Rate Limit</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Rate Limit"
                            defaultValue={rateLimit}
                            onChange={e => handleRateLimit(e.target.value)}
                            onBlur={validateRateLimit} />
                          <span style={{ color: "red" }}>
                            {rateLimitError}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                      <Form.Group controlId="formEnableDelete" style={{ margin: 0 }}>
                        <Form.Check
                          type="checkbox"
                          label="Delete protection"
                          checked={enableDelete}
                          onChange={(e) => setEnableDelete(e.target.checked)}
                        />
                      </Form.Group>
                      <div>
                        <Button variant="secondary" onClick={handleCloseEditSite}>
                          Cancel
                        </Button>{' '}
                        <Button
                          disabled={!areAllFieldsFilled}
                          variant="primary"
                          onClick={() => {
                            editSite();
                            handleCloseAddSite();
                          }}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Modal.Footer>

                </Modal>

                <Modal show={showSiteScript} onHide={handleCloseSiteScript} size="lg">
                  <Modal.Header closeButton>
                    {userRole === "ADMIN" ?
                      <Modal.Title>Client Script</Modal.Title>
                      :
                      <Modal.Title>Site Script</Modal.Title>
                    }
                  </Modal.Header>
                  <Modal.Body>
                    HTML Code:
                    <div className="container p-3 my-3 border">
                      <button className="btn btn-primary" style={{ float: "right" }}
                        onClick={() => {
                          navigator.clipboard.writeText(htmlStr);
                          info("Script copied to clipboard.", true);
                        }}>
                        Copy
                      </button>
                      <HtmlContentDisplay htmlContent={htmlStr} />
                    </div>
                    Javascript Code:
                    <div className="container p-3 my-3 border">
                      <button className="btn btn-primary" style={{ float: "right" }}
                        onClick={() => {
                          navigator.clipboard.writeText(javaScriptStr);
                          info("Script copied to clipboard.", true);
                        }}>
                        Copy
                      </button>
                      <HtmlContentDisplay htmlContent={javaScriptStr} />
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            }
            <div className="table-responsive">
              <table className="table-hover table-striped table">
                <thead>
                  <tr>
                    <th className="text-center">Name</th>
                    <th className="text-center">Document Count</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(userRole === "ADMIN" || userRole === "SITEADMIN") &&
                    userSiteData.map((item, index) => (
                      <tr key={index} >
                        <td>
                          <Link
                            className={`${location.pathname === '/site' ? 'active' : ''} nav-link `}
                            aria-current="page"
                            to="/site/docs"
                            state={{ siteId: item.id, siteName: item.name, siteKey: item.key, enableFileTrain: item.enable_file_train }}
                          >
                            <span className="item-name">{item.name} </span>
                          </Link>
                        </td>
                        <td width="10%" style={{ textAlign: "center" }}>
                          <span className="item-name">{item.doc_count ? item.doc_count : 0} </span>
                        </td>
                        <td width="25%">
                          <div style={{ textAlign: "center" }}>
                            <Link
                              className="btn-sm btn-icon text-primary flex-end"
                              data-bs-toggle="tooltip"
                              title={userRole === "ADMIN" ? "Edit Client" : "Edit Site"}
                              to="#"
                              onClick={() => {
                                handleShowEditSite(
                                  item.id,
                                  item.name,
                                  item.key,
                                  item.bot_name,
                                  item.banner_url,
                                  item.rate_limit,
                                  item.enable_file_train,
                                  item.domain_url,
                                  item.qdrant_db_url,
                                  item.enable_full_site_training,
                                  item.auto_training_interval,
                                  item.enable_delete
                                );
                              }}
                            >
                              <span className="btn-inner">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M13.7476 20.4428H21.0002"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M11.021 6.00098L16.4732 10.1881"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                </svg>
                              </span>
                            </Link>
                            <Link
                              className="btn-sm btn-icon text-primary flex-end"
                              data-bs-toggle="tooltip"
                              title="Trained Documents"
                              to="/site/docs"
                              state={{ siteId: item.id, siteName: item.name, siteKey: item.key, enableFileTrain: item.enable_file_train }}
                            >
                              <span className="item-name">
                                <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.7161 16.2234H8.49609"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M15.7161 12.0369H8.49609"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M11.2521 7.86011H8.49707"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.909 2.74976C15.909 2.74976 8.23198 2.75376 8.21998 2.75376C5.45998 2.77076 3.75098 4.58676 3.75098 7.35676V16.5528C3.75098 19.3368 5.47298 21.1598 8.25698 21.1598C8.25698 21.1598 15.933 21.1568 15.946 21.1568C18.706 21.1398 20.416 19.3228 20.416 16.5528V7.35676C20.416 4.57276 18.693 2.74976 15.909 2.74976Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                </svg>
                              </span>
                            </Link>
                            <Link
                              className="btn-sm btn-icon text-primary flex-end"
                              data-bs-toggle="tooltip"
                              title="Manage User"
                              to="/site/users"
                              state={{ siteId: item.id, siteName: item.name }}
                            >
                              <span className="btn-inner">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.8877 10.8967C19.2827 10.7007 20.3567 9.50473 20.3597 8.05573C20.3597 6.62773 19.3187 5.44373 17.9537 5.21973"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M19.7285 14.2505C21.0795 14.4525 22.0225 14.9255 22.0225 15.9005C22.0225 16.5715 21.5785 17.0075 20.8605 17.2815"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.8867 14.6638C8.67273 14.6638 5.92773 15.1508 5.92773 17.0958C5.92773 19.0398 8.65573 19.5408 11.8867 19.5408C15.1007 19.5408 17.8447 19.0588 17.8447 17.1128C17.8447 15.1668 15.1177 14.6638 11.8867 14.6638Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.8869 11.888C13.9959 11.888 15.7059 10.179 15.7059 8.069C15.7059 5.96 13.9959 4.25 11.8869 4.25C9.7779 4.25 8.0679 5.96 8.0679 8.069C8.0599 10.171 9.7569 11.881 11.8589 11.888H11.8869Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M5.88509 10.8967C4.48909 10.7007 3.41609 9.50473 3.41309 8.05573C3.41309 6.62773 4.45409 5.44373 5.81909 5.21973"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    d="M4.044 14.2505C2.693 14.4525 1.75 14.9255 1.75 15.9005C1.75 16.5715 2.194 17.0075 2.912 17.2815"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                </svg>
                              </span>
                            </Link>
                            <Link
                              className="btn-sm btn-icon text-primary flex-end"
                              data-bs-toggle="tooltip"
                              title="Manage Category"
                              to="/site/categories"
                              state={{ siteId: item.id, siteName: item.name, siteKey: item.key }}
                            >
                              <span className="btn-inner">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                  </path>
                                </svg>
                              </span>
                            </Link>
                            {(userRole === "ADMIN") &&
                              <Link
                                className="btn-sm btn-icon text-primary flex-end"
                                data-bs-toggle="tooltip"
                                title="Full Site Training"
                                to="#"
                                isDisabled
                                onClick={() => { handleShowFSTConfirm(item.id, item.name, item.key, item.enable_full_site_training); }}
                              >
                                <span className="item-name">
                                  <	svg width="20" height="20" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none">
                                    <path
                                      stroke="currentColor"
                                      d="M39.93,55.72A24.86,24.86,0,1,1,56.86,32.15a37.24,37.24,0,0,1-.73,6">
                                    </path>
                                    <path
                                      stroke="currentColor"
                                      d="M37.86,51.1A47,47,0,0,1,32,56.7">
                                    </path>
                                    <path
                                      stroke="currentColor"
                                      d="M32,7A34.14,34.14,0,0,1,43.57,30a34.07,34.07,0,0,1,.09,4.85">
                                    </path>
                                    <path
                                      stroke="currentColor"
                                      d="M32,7A34.09,34.09,0,0,0,20.31,32.46c0,16.2,7.28,21,11.66,24.24">
                                    </path>
                                    <line
                                      stroke="currentColor"
                                      x1="10.37"
                                      y1="19.9"
                                      x2="53.75"
                                      y2="19.9">
                                    </line>
                                    <line
                                      stroke="currentColor"
                                      x1="32"
                                      y1="6.99"
                                      x2="32"
                                      y2="56.7">
                                    </line>
                                    <line
                                      stroke="currentColor"
                                      x1="11.05"
                                      y1="45.48"
                                      x2="37.04"
                                      y2="45.48">
                                    </line>
                                    <line
                                      stroke="currentColor"
                                      x1="7.14"
                                      y1="32.46"
                                      x2="56.86"
                                      y2="31.85">
                                    </line>
                                    <path
                                      stroke="currentColor"
                                      d="M53.57,57,58,52.56l-8-8,4.55-2.91a.38.38,0,0,0-.12-.7L39.14,37.37a.39.39,0,0,0-.46.46L42,53.41a.39.39,0,0,0,.71.13L45.57,49Z">
                                    </path>
                                  </svg>
                                </span>
                              </Link>
                            }
                            <Link
                              className="btn-sm btn-icon text-primary flex-end"
                              data-bs-toggle="tooltip"
                              title="Generate Chatbot Script"
                              to="#"
                              onClick={() => { handleShowSiteScript(item.bot_name, item.key) }}
                            >
                              <span className="btn-inner">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="3" y="0" width="18" height="24" fill="black">
                                    <rect fill="white" x="3" width="18" height="24" />
                                    <path d="M4 3.00004C4 1.89547 4.89543 1.00004 6 1.00004H13.0801C13.664 1.00004 14.2187 1.25517 14.5986 1.69845L19.5185 7.43826C19.8292 7.80075 20 8.26243 20 8.73985V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3.00004Z" />
                                  </mask>
                                  <path
                                    d="M4 3.00004C4 1.89547 4.89543 1.00004 6 1.00004H13.0801C13.664 1.00004 14.2187 1.25517 14.5986 1.69845L19.5185 7.43826C19.8292 7.80075 20 8.26243 20 8.73985V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3.00004Z"
                                    stroke="#130F26"
                                    strokeWidth="2"
                                    mask="url(#path-1-outside-1)">
                                  </path>
                                  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="18" height="24">
                                    <mask id="path-2-outside-2" mask-type="luminance" maskUnits="userSpaceOnUse" x="3" y="0" width="18" height="24" fill="black">
                                      <rect fill="white" x="3" width="18" height="24" />
                                      <path d="M4 3.00004C4 1.89547 4.89543 1.00004 6 1.00004H13.0801C13.664 1.00004 14.2187 1.25517 14.5986 1.69845L19.5185 7.43826C19.8292 7.80075 20 8.26243 20 8.73985V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3.00004Z" />
                                    </mask>
                                    <path
                                      d="M4 3.00004C4 1.89547 4.89543 1.00004 6 1.00004H13.0801C13.664 1.00004 14.2187 1.25517 14.5986 1.69845L19.5185 7.43826C19.8292 7.80075 20 8.26243 20 8.73985V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3.00004Z"
                                      fill="#130F26">
                                    </path>
                                    <path
                                      d="M4 3.00004C4 1.89547 4.89543 1.00004 6 1.00004H13.0801C13.664 1.00004 14.2187 1.25517 14.5986 1.69845L19.5185 7.43826C19.8292 7.80075 20 8.26243 20 8.73985V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3.00004Z"
                                      stroke="#130F26"
                                      strokeWidth="2"
                                      mask="url(#path-2-outside-2)">
                                    </path>
                                  </mask>
                                  <path
                                    id="Rectangle 21"
                                    d="M14 6V0L21 8H16C14.8954 8 14 7.10457 14 6Z"
                                    stroke="#130F26">
                                  </path>
                                  <mask id="path-4-inside-3" fill="white">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7 14.5945L8.99429 12.1334C9.12172 11.9761 9.34898 11.9549 9.50189 12.0859C9.6548 12.217 9.67546 12.4507 9.54804 12.6079L7.93828 14.5945L9.54804 16.581C9.67546 16.7383 9.6548 16.972 9.50189 17.103C9.34898 17.2341 9.12172 17.2128 8.99429 17.0556L7 14.5945Z">
                                    </path>
                                  </mask>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7 14.5945L8.99429 12.1334C9.12172 11.9761 9.34898 11.9549 9.50189 12.0859C9.6548 12.217 9.67546 12.4507 9.54804 12.6079L7.93828 14.5945L9.54804 16.581C9.67546 16.7383 9.6548 16.972 9.50189 17.103C9.34898 17.2341 9.12172 17.2128 8.99429 17.0556L7 14.5945Z"
                                    stroke="#130F26"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    mask="url(#path-4-inside-3)">
                                  </path>
                                  <path
                                    id="Line 71 (Stroke)"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.771 11.1638C13.9576 11.2542 14.0356 11.4769 13.9451 11.6611L10.9973 17.6664C10.9069 17.8506 10.6823 17.9267 10.4957 17.8363C10.3091 17.7458 10.2311 17.5232 10.3215 17.3389L13.2693 11.3336C13.3598 11.1494 13.5844 11.0733 13.771 11.1638Z"
                                    fill="#130F26">
                                  </path>
                                  <mask id="path-6-inside-4" fill="white">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M17 14.5945L15.0057 17.0556C14.8783 17.2128 14.651 17.2341 14.4981 17.1031C14.3452 16.972 14.3245 16.7383 14.452 16.581L16.0617 14.5945L14.452 12.6079C14.3245 12.4507 14.3452 12.217 14.4981 12.0859C14.651 11.9549 14.8783 11.9761 15.0057 12.1334L17 14.5945Z">
                                    </path>
                                  </mask>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17 14.5945L15.0057 17.0556C14.8783 17.2128 14.651 17.2341 14.4981 17.1031C14.3452 16.972 14.3245 16.7383 14.452 16.581L16.0617 14.5945L14.452 12.6079C14.3245 12.4507 14.3452 12.217 14.4981 12.0859C14.651 11.9549 14.8783 11.9761 15.0057 12.1334L17 14.5945Z"
                                    stroke="#130F26"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    mask="url(#path-6-inside-4)">
                                  </path>
                                </svg>
                              </span>
                            </Link>
                            {(userRole === "ADMIN") &&
                              <Link
                                className={`btn-sm btn-icon ${!item.enable_delete ? 'text-danger' : 'text-muted'}`}  // Use a different class for disabled state
                                data-bs-toggle="tooltip"
                                title={!item.enable_delete ? "Delete Client" : "This site cannot be deleted because it is delete-protected. Please remove the delete protection in the site configuration and try again."}  // Tooltip based on enable_delete
                                to="#"
                                onClick={() => {
                                  if (!item.enable_delete) {
                                    handleShowDeleteSite(item.id, item.name, item.key);
                                  }
                                }}
                                style={{ cursor: !item.enable_delete ? 'pointer' : 'not-allowed' }}  // Change cursor style when disabled
                              >
                                <span className="btn-inner">
                                  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round">
                                    </path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round">
                                    </path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round">
                                    </path>
                                  </svg>
                                </span>
                              </Link>

                            }
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                  {userRole === "TRAINER" &&
                    userSiteData.map((item, index) => (
                      <tr key={index} >
                        <td>
                          <Link
                            className={`${location.pathname === '/site' ? 'active' : ''} nav-link `}
                            aria-current="page"
                            to="/site/docs"
                            state={{ siteId: item.id, siteName: item.name, siteKey: item.key, enableFileTrain: item.enable_file_train }} >
                            <span className="item-name">{item.name} </span>
                          </Link>
                        </td>
                        <td width="10%" style={{ textAlign: "center" }}>
                          <span className="item-name">{item.doc_count} </span>
                        </td>
                        <td width="20%">
                          <div style={{ textAlign: "center" }}>
                            <Link className="btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="Trained Documents"
                              to="/site/docs"
                              state={{ siteId: item.id, siteName: item.name, siteKey: item.key, enableFileTrain: item.enable_file_train }}
                            >
                              <span className="item-name">
                                <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15.7161 16.2234H8.49609" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M15.7161 12.0369H8.49609" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M11.2521 7.86011H8.49707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.909 2.74976C15.909 2.74976 8.23198 2.75376 8.21998 2.75376C5.45998 2.77076 3.75098 4.58676 3.75098 7.35676V16.5528C3.75098 19.3368 5.47298 21.1598 8.25698 21.1598C8.25698 21.1598 15.933 21.1568 15.946 21.1568C18.706 21.1398 20.416 19.3228 20.416 16.5528V7.35676C20.416 4.57276 18.693 2.74976 15.909 2.74976Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            {/* Custom Pagination Controls */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                {/* Left arrow */}
                {currentRangeStart > 1 && (
                  <button
                    onClick={handlePrevRange}
                    style={{
                      width: '35px',
                      height: '35px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ddd',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    &lt;
                  </button>
                )}

                {/* Page numbers */}
                {visiblePageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    style={{
                      width: '35px',
                      height: '35px',
                      backgroundColor: currentPage === number ? '#007bff' : '#f0f0f0',
                      color: currentPage === number ? 'white' : 'black',
                      border: currentPage === number ? '1px solid #007bff' : '1px solid #ddd',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {number}
                  </button>
                ))}

                {/* Ellipsis if there are more pages */}
                {currentRangeStart + pagesToShow <= totalPages && (
                  <span style={{ width: '30px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'default' }}>...</span>
                )}

                {/* Right arrow */}
                {currentRangeStart + pagesToShow <= totalPages && (
                  <button
                    onClick={handleNextRange}
                    style={{
                      width: '35px',
                      height: '35px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ddd',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    &gt;
                  </button>
                )}

                {/* Items per page dropdown */}
                <div style={{ marginBottom: '10px' }}>
                  <label htmlFor="itemsPerPage" style={{ marginLeft: '10px' }}>Items per page:</label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ padding: '5px' }}
                  >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                  </select>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <FullSiteTrainingConfirmation
        showModal={showFSTConfirm}
        confirmModal={trainFullSite}
        hideModal={handleCloseFSTConfirm}
        id={siteId}
        key={key}
        message={confirmFTSMessage}
        proceedFST={proceedFST}
      />
      <DeleteConfirmation
        showModal={showDeleteSite}
        proceedDelete="true"
        confirmModal={deleteSite}
        hideModal={handleCloseDeleteSite}
        id={siteId}
        key={key}
        message={deleteMessage}
      />
    </Row>
  );
})

export default Site;

